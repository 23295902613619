<template>
  <div class="flex flex-col min-h-screen" id="app">
    <!-- Header -->
    <header class="bg-blue-300 text-white p-2">
      <div class="container mx-auto flex justify-between items-center">
        <div @click="redirectToFirstAvailableTab" class="hover:scale-110 text-2xl font-bold">
          <img src="../assets/GM_Digital_Doctor_Logo_RGB_150dpi.png" alt="Guard.me logo" class="h-16 ">
        </div>

        <div class="user-actions flex text-black space-x-4">
          <button v-if="showLogin" @click="navigateTo('/login')"
                  class="flex items-center px-4 py-2 bg-blue-500 text-white rounded hover:bg-blue-600">
            <svg class="w-5 h-5 mr-2" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
              <path d="M217.9 105.9L340.7 228.7c7.2 7.2 11.3 17.1 11.3 27.3s-4.1 20.1-11.3 27.3L217.9 406.1c-6.4 6.4-15 9.9-24 9.9c-18.7 0-33.9-15.2-33.9-33.9l0-62.1L32 320c-17.7 0-32-14.3-32-32l0-64c0-17.7 14.3-32 32-32l128 0 0-62.1c0-18.7 15.2-33.9 33.9-33.9c9 0 17.6 3.6 24 9.9zM352 416l64 0c17.7 0 32-14.3 32-32l0-256c0-17.7-14.3-32-32-32l-64 0c-17.7 0-32-14.3-32-32s14.3-32 32-32l64 0c53 0 96 43 96 96l0 256c0 53-43 96-96 96l-64 0c-17.7 0-32-14.3-32-32s14.3-32 32-32z"/>
            </svg>
            Login
          </button>

          <!-- User dropdown -->
          <div v-if="showProfile" class="relative" @click="toggleUserDropdown">
            <div class="flex items-center space-x-2 text-black  hover:text-blue-100 cursor-pointer">
              <svg class="w-6 h-6" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512">
                <path
                    d="M304 128a80 80 0 1 0 -160 0 80 80 0 1 0 160 0zM96 128a128 128 0 1 1 256 0A128 128 0 1 1 96 128zM49.3 464l349.5 0c-8.9-63.3-63.3-112-129-112l-91.4 0c-65.7 0-120.1 48.7-129 112zM0 482.3C0 383.8 79.8 304 178.3 304l91.4 0C368.2 304 448 383.8 448 482.3c0 16.4-13.3 29.7-29.7 29.7L29.7 512C13.3 512 0 498.7 0 482.3z"/>
              </svg>
              <span class="text-lg">{{ userName }}</span>
            </div>
            <div v-if="showDropdown"
                 class="absolute right-0 mt-2 w-48 bg-white border rounded-md shadow-lg z-[100]">
              <router-link to="/profile"
                           class="block px-4 py-2 text-gray-800 hover:bg-gray-200 flex items-center">
                <svg class="w-4 h-4 mr-2" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 576 512">
                  <path
                      d="M512 80c8.8 0 16 7.2 16 16l0 320c0 8.8-7.2 16-16 16L64 432c-8.8 0-16-7.2-16-16L48 96c0-8.8 7.2-16 16-16l448 0zM64 32C28.7 32 0 60.7 0 96L0 416c0 35.3 28.7 64 64 64l448 0c35.3 0 64-28.7 64-64l0-320c0-35.3-28.7-64-64-64L64 32zM208 256a64 64 0 1 0 0-128 64 64 0 1 0 0 128zm-32 32c-44.2 0-80 35.8-80 80c0 8.8 7.2 16 16 16l192 0c8.8 0 16-7.2 16-16c0-44.2-35.8-80-80-80l-64 0zM376 144c-13.3 0-24 10.7-24 24s10.7 24 24 24l80 0c13.3 0 24-10.7 24-24s-10.7-24-24-24l-80 0zm0 96c-13.3 0-24 10.7-24 24s10.7 24 24 24l80 0c13.3 0 24-10.7 24-24s-10.7-24-24-24l-80 0z"/>
                </svg>
                Profile
              </router-link>
              <button @click="logout"
                      class="block w-full text-left px-4 py-2 text-gray-800 hover:bg-gray-200 flex items-center">
                <svg class="w-4 h-4 mr-2" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
                  <path
                      d="M377.9 105.9L500.7 228.7c7.2 7.2 11.3 17.1 11.3 27.3s-4.1 20.1-11.3 27.3L377.9 406.1c-6.4 6.4-15 9.9-24 9.9c-18.7 0-33.9-15.2-33.9-33.9l0-62.1-128 0c-17.7 0-32-14.3-32-32l0-64c0-17.7 14.3-32 32-32l128 0 0-62.1c0-18.7 15.2-33.9 33.9-33.9c9 0 17.6 3.6 24 9.9zM160 96L96 96c-17.7 0-32 14.3-32 32l0 256c0 17.7 14.3 32 32 32l64 0c17.7 0 32 14.3 32 32s-14.3 32-32 32l-64 0c-53 0-96-43-96-96L0 128C0 75 43 32 96 32l64 0c17.7 0 32 14.3 32 32s-14.3 32-32 32z"/>
                </svg>
                Logout
              </button>
            </div>
          </div>
        </div>
      </div>
    </header>

    <!-- Main content -->
    <main class="flex-1">
      <router-view @update-show-login="updateShowLogin" @update-show-profile="updateShowProfile"
                   @update-user-name="updateUserName"></router-view>
    </main>

    <!-- Footer -->
    <footer class="text-center p-4 bg-blue-300 text-white">
      <p>&copy; 2024 Guard.me. All rights reserved.</p>

      <div class="mt-2">
        <router-link class="text-gray-400 hover:text-white mr-4" :to="{ path: '/privacy-notice' }"
                     target="_blank">Privacy Notice
        </router-link>
        <router-link class="text-gray-400 hover:text-white mr-4" :to="{ path: '/terms-of-use' }"
                     target="_blank">Terms of Use
        </router-link>
        <router-link class="text-gray-400 hover:text-white" :to="{ path: '/cookie-notice' }"
                     target="_blank">Cookie Notice
        </router-link>
      </div>
    </footer>
  </div>
</template>

<script>
import axios from 'axios';
import tokenService from "@/services/TokenService";
import AuthenticationService from "@/services/AuthenticationService";
import tabService from "@/services/TabService";

export default {
  name: 'BasicLayout',
  beforeCreate() {
    const me = this;
    axios.interceptors.response.use(function (response) {
      return response
    }, function (error) {
      const statusCode = error.response.status;
      const message = error.response?.message || error.response?.data?.message;
      if (statusCode == 403 && message == 'User access is disabled by the admin') {
        return AuthenticationService.disable(me.$store, me.$router)
      }
      return Promise.reject(error)
    });
  },
  data() {
    return {
      apiUrl: process.env.VUE_APP_API_BASE_URL,
      showLogin: true,
      showProfile: false,
      showDropdown: false,
      userName: this.$store.getters.userName,
    };
  },
  mounted() {
    this.userName = this.$store.getters.userName;
    // this.userName = localStorage.getItem('userName');

    if (this.userName != null && this.userName !== '') {
      this.showProfile = true;
      this.showLogin = false;
    }

    // Load OneTrust cookies consent notice
    const script1 = document.createElement('script');
    script1.src = 'https://cdn.cookielaw.org/scripttemplates/otSDKStub.js';
    script1.setAttribute('type', 'text/javascript');
    script1.setAttribute('charset', 'UTF-8');


    // Check the current environment based on the apiUrl
    if (this.apiUrl.includes('sandbox')) {
      // If the apiUrl includes 'sandbox', it means we are in the sandbox environment
      // Set the 'data-domain-script' attribute to the specific value for the sandbox environment
      script1.setAttribute('data-domain-script', '018fa6e2-9083-7e87-9dca-faf15bae572c');
    } else if (this.apiUrl.includes('qa')) {
      // If the apiUrl includes 'qa', it means we are in the QA environment
      // Set the 'data-domain-script' attribute to the specific value for the QA environment
      script1.setAttribute('data-domain-script', '018fa72c-2abb-7832-ae49-9f0312d4ee3c');
    } else {
      // If the apiUrl does not include 'sandbox' or 'qa', it means we are in the production environment, ignore develop for now.
      script1.setAttribute('data-domain-script', '66594e72-25fd-440b-a113-44a211cac1bb');
    }


    document.body.appendChild(script1);

    const script2 = document.createElement('script');
    script2.type = 'text/javascript';
    script2.text = `
      function OptanonWrapper() {
        // Add code here to react to the user's choice about their cookie preferences
      }
    `;
    document.body.appendChild(script2);
  },
  methods: {
    async redirectToFirstAvailableTab() {
      const token = this.$store.state.userToken;
      if (tokenService.isTokenExpired(token)) {
        AuthenticationService.logout(this.$store, this.$router);
        return false;
      }
      axios.defaults.withCredentials = true;
      axios.defaults.withXSRFToken = true;
      const $config = {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      };
      const tabUrl = await tabService.getFirstAvailableTabUrl($config);
      this.$router.push(tabUrl);
    },
    navigateTo(path) {
      this.$router.push(path);
    },
    updateShowLogin(value) {
      this.showLogin = value;
    },
    updateShowProfile(value) {
      this.showProfile = value;
    },
    updateUserName(value) {
      this.userName = value;

      if (value !== null && value !== '') {
        this.showLogin = false;
        this.showProfile = true;
      }
    },
    toggleUserDropdown() {
      this.showDropdown = !this.showDropdown;
    },
    async logout() {
      const me = this;
      const token = this.$store.state.userToken;
      if (tokenService.isTokenExpired(token)) {
        return AuthenticationService.logout(me.$store, me.$router);
      }

      axios.defaults.withCredentials = true;
      axios.defaults.withXSRFToken = true;

      const config = {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      };


      await axios.post(this.apiUrl + '/api/cognito/logout', {}, config)
          .then(() => {
            AuthenticationService.logout(me.$store, me.$router, true);
          })
          .catch(error => {
            console.error('There was an error!', error);
            return AuthenticationService.logout(me.$store, me.$router, "force logout");
          });
      this.showDropdown = false;
    },
  },
}
</script>

<style scoped>
#app {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

footer {
  position: fixed;
  bottom: 0;
  width: 100%;
  z-index: 999;
  /* Ensure footer is on top of other content */
}

main {
  flex: 1;
}
</style>
