<template>
  <div class="w-4/12 mx-auto mt-8 border border-gray-300 p-8 rounded-md">
    <h2 class="text-3xl font-semibold mb-6 text-center">Login With Temporary Password</h2>
    <form @submit.prevent="loginWithTempPassword" class="space-y-4">
      <div>
        <label for="email" class="block text-sm font-medium text-gray-600">Email</label>
        <input v-model="email" id="email" name="email" type="email" class="mt-1 p-2 w-full border rounded-md">
      </div>

      <div>
        <label for="oldPassword" class="block text-sm font-medium text-gray-600">Temporary Password</label>
        <input
            v-model="tempPassword"
            id="tempPassword"
            name="tempPassword"
            type="password"
            class="mt-1 p-2 w-full border rounded-md">
      </div>

      <div>
        <label for="newPassword" class="block text-sm font-medium text-gray-600">New Password</label>
        <input
            v-model="newPassword"
            @input="validatePassword()"
            @focus="showTooltip = true"
            @blur="showTooltip = false; validatePassword()"
            id="newPassword"
            name="newPassword"
            type="password"
            class="mt-1 p-2 w-full border rounded-md">
      </div>

      <div v-if="showTooltip" class="absolute bg-white border border-gray-300 p-2 rounded shadow-md text-sm z-10 ">
        <ul class="text-gray-600">
          <li :class="{ 'text-green-600': validLength }">At least 8 characters</li>
          <li :class="{ 'text-green-600': hasUppercase }">At least 1 uppercase letter</li>
          <li :class="{ 'text-green-600': hasLowercase }">At least 1 lowercase letter</li>
          <li :class="{ 'text-green-600': hasSpecialCharacter }">At least 1 special character (@$!%*#?&)</li>
          <li :class="{ 'text-green-600': hasNumeric }">At least 1 numeric character</li>
        </ul>
      </div>

      <div>
        <label for="confirmPassword" class="block text-sm font-medium text-gray-600">Confirm Password</label>
        <input v-model="confirmPassword" id="confirmPassword" name="confirmPassword" type="password"
               class="mt-1 p-2 w-full border rounded-md">
      </div>



      <div class="text-sm">
        <button
            @click.prevent="redirectToLoginPage"
            class="font-medium text-indigo-600 hover:text-indigo-500"
        >
          Go to Login
        </button>
      </div>

      <div>
        <button type="submit"
                :disabled="!isPasswordValid"
                class="w-full p-2 bg-blue-500 text-white rounded-md hover:bg-blue-600 focus:outline-none focus:ring focus:border-blue-300 disabled:opacity-50 disabled:cursor-not-allowed">
          Continue
        </button>
      </div>

      <div v-if="infoMessage && !errorMessage"
           class="mb-4 p-2 text-center text-green-600 bg-green-100 border border-green-300 rounded">
        {{ infoMessage }}
      </div>
      <div v-if="errorMessage" class="mb-4 p-2 text-center text-red-600 bg-red-100 border border-red-300 rounded">
        {{ errorMessage }}
      </div>
    </form>
  </div>
</template>

<script>

import axios from 'axios';

export default {
  data() {
    return {
      email: '',
      infoMessage: '',
      errorMessage: '',
      tempPassword: '',
      newPassword: '',
      confirmPassword: '',
      showTooltip: false,
      validLength: false,
      hasUppercase: false,
      hasLowercase: false,
      hasNumeric: false,
      hasSpecialCharacter: false,
    };
  },
  computed: {
    apiUrl() {
      return process.env.VUE_APP_API_BASE_URL;
    },
    isPasswordValid() {
      return (
          this.validLength &&
          this.hasUppercase &&
          this.hasLowercase &&
          this.hasSpecialCharacter &&
          this.hasNumeric
      );
    },
  },
  methods: {
    redirectToLoginPage() {
      // Redirect to the login page
      this.$router.push('/login');
    },
    async loginWithTempPassword() {
      //check if passwords match
      if (this.newPassword !== this.confirmPassword) {
        this.errorMessage = "Passwords don't match!";
        return;
      }

      this.errorMessage = '';
      this.infoMessage = '';
      await axios.post(this.apiUrl + `/api/cognito/login-by-temporary-password`, {
        email: this.email,
        temporary_password: this.tempPassword,
        new_password: this.newPassword,
        new_password_confirmation: this.confirmPassword,
      }).then(async (response) => {
        this.errorMessage = '';
        this.infoMessage = 'Registration verified';
        this.userToken = response.data.access_token;
        this.refreshToken = response.data.refresh_token;
        this.$store.commit('setUserToken', this.userToken);
        this.$store.commit('setRefreshToken', this.refreshToken);

        this.$emit('update-show-login', false);
        this.$emit('update-show-profile', true);

        await this.getUserName();
        // Redirect to the dashboard page
        this.$router.push('/dashboard/');
      }).catch((error) => {
        console.error('Failed to verify registration:', error.response || error);
        this.errorMessage = error.response.data.message;
      });
    },
    validatePassword() {
      const password = this.newPassword;

      this.validLength = password.length >= 8;
      this.hasUppercase = /[A-Z]/.test(password);
      this.hasLowercase = /[a-z]/.test(password);
      this.hasSpecialCharacter = /[@$!%*#?&]/.test(password);
      this.hasNumeric = /\d/.test(password);
    },
    async getUserName() {
      const token = this.$store.state.userToken;
      const config = {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      };
      await axios.get(this.apiUrl + '/api/accounts/username', config)
          .then(response => {
            this.userName = response.data.username;
            this.$store.commit('setUserName', this.userName);
            this.$emit('update-user-name', this.userName);
          })
          .catch(error => {
            console.error('There was an error!', error);
          });
    },
  },
  props: {
    showVerifyRegistration: {
      type: Boolean,
      required: true,
    },
  },
};

</script>

<style scoped>

</style>