<template>
  <div v-if="showEmailsTab" class="container mx-auto p-4">
    <!-- List Emails -->
    <h2 class="text-2xl font-bold mb-4">Email List</h2>
    <ul class="list-disc space-y-2">
      <li v-for="(email, index) in emails" :key="index" class="flex items-center justify-between border-b pb-2">
        <div>
          <span class="font-bold">{{ email.name }}</span> - {{ email.email }}
        </div>
        <div class="space-x-2">
          <button @click="editEmail(index)" class="text-blue-500 hover:underline">Edit</button>
          <button @click="deleteEmail(index)" class="text-red-500 hover:underline">Delete</button>
        </div>
      </li>
    </ul>

    <!-- Create or Update Email Form -->
    <h2 class="text-2xl font-bold mt-4">Create/Update Email</h2>
    <form @submit.prevent="saveEmail" class="mt-4 space-y-4">
      <div class="flex flex-col">
        <label for="name" class="text-gray-700 text-sm font-bold mb-2">Name:</label>
        <input type="text" id="name" v-model="currentEmail.name" class="input" />
      </div>

      <div class="flex flex-col">
        <label for="emailAddress" class="text-gray-700 text-sm font-bold mb-2">Email Address:</label>
        <input type="email" id="emailAddress" v-model="currentEmail.email" class="input" />
      </div>

      <button type="submit" class="btn-blue">Save Email</button>
    </form>
  </div>
</template>

<script>
import axios from 'axios';
export default {
  props: {
    showEmailsTab: {
      type: Boolean,
      required: true,
    }
  },
  data() {
    return {
      emails: [],
      currentEmail: { name: '', email: '' },
      editingIndex: -1,
      apiUrl: process.env.VUE_APP_API_BASE_URL,
    };
  },
  mounted() {
    this.getEmails();
  },
  methods: {
    saveEmail() {
      if (this.editingIndex === -1) {
        // Create a new email
        this.emails.push({...this.currentEmail});
        this.createEmail(this.currentEmail);
      } else {
        // Update existing email
        this.emails[this.editingIndex] = {...this.currentEmail};
        this.editingIndex = -1; // Reset editing index after update
      }

      // Clear the form
      this.currentEmail = {name: '', email: ''};
    },
    editEmail(index) {
      // Set the currentEmail and activate edit mode
      this.currentEmail = {...this.emails[index]};
      this.editingIndex = index;
    },
    deleteEmail(index) {
      // Delete the email at the specified index
      this.emails.splice(index, 1);

      // Reset form if deleting the currently edited email
      if (this.editingIndex === index) {
        this.currentEmail = {name: '', email: ''};
        this.editingIndex = -1;
      }
    },
    async getEmails() {
      const token = this.$store.state.userToken;
      const config = {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      };
      await axios.get(this.apiUrl + '/api/emails', config)
        .then(response => {
          this.emails = response.data;
        })
        .catch(error => {
          console.error('There was an error!', error);
        });
    },
    async createEmail(email) {
      const token = this.$store.state.userToken;
      const config = {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      };
      await axios.post(this.apiUrl + '/api/emails', email, config)
        .then(response => {
          //fixme: feature not in current plan, need to delete after testing
          console.log('Email created:', response.data);
        })
        .catch(error => {
          console.error('There was an error!', error);
        });
    },
  },
};
</script>

<style scoped>
.input {
  border: 1px solid #e2e8f0;
  padding: 0.5rem;
  border-radius: 0.25rem;
}

.btn-blue {
  background-color: #3490dc;
  color: #ffffff;
  padding: 0.75rem 1rem;
  border-radius: 0.25rem;
  cursor: pointer;
  transition: background-color 0.3s;
}

.btn-blue:hover {
  background-color: #2779bd;
}
</style>
