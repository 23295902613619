// tokenService.js
const tokenService = {
    isTokenExpired: (token) => {
        if (!token) {
            return true;
        }

        try {
            const decoded = JSON.parse(atob(token.split('.')[1]));
            return decoded.exp < Date.now() / 1000;
        } catch (e) {
            return false;
        }
    }
}

export default tokenService;
