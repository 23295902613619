<template>
    <!--  <meta name="csrf-token" content="{{ csrf_token() }}">-->
    <div v-if="!showVerifyRegistration && !showMFAInput" id="pre_login"
        class="w-4/12 mx-auto mt-8 border border-gray-300 p-8 rounded-md">
        <h2 class="text-3xl font-semibold mb-6 text-center">Login</h2>
        <form @submit.prevent="loginWithChallenge" class="space-y-4">
            <div>
                <label for="email" class="block text-sm font-medium text-gray-600">Email</label>
                <input v-model="email" id="email" name="email" type="email" class="mt-1 p-2 w-full border rounded-md">
            </div>

            <div>
                <label for="password" class="block text-sm font-medium text-gray-600">Password</label>
                <input v-model="password" id="password" name="password" type="password"
                    class="mt-1 p-2 w-full border rounded-md">
            </div>

            <div class="flex items-center justify-between">
                <div class="text-sm">
                    <a class="font-medium text-indigo-600 hover:text-indigo-500"
                        @click.prevent="redirectToForgetPasswordPage">Forgot your password?</a>
                </div>
            </div>

            <div>
                <button :disabled='loginClicked' type="submit"
                    class="w-full p-2 bg-blue-500 text-white rounded-md hover:bg-blue-600 focus:outline-none focus:ring focus:border-blue-300 disabled:bg-gray-500 disabled:cursor-not-allowed disabled:opacity-50">
                    Login
                </button>
            </div>

            <div v-if="infoMessage && !errorMessage"
                class="mb-4 p-2 text-center text-green-600 bg-green-100 border border-green-300 rounded">
                {{ infoMessage }}
            </div>
            <div v-if="errorMessage" class="mb-4 p-2 text-center text-red-600 bg-red-100 border border-red-300 rounded">
                {{ errorMessage }}
            </div>
        </form>

        <div class="text-sm mt-4">
            First time using Digital Doctor? <button @click.prevent="redirectToResetPasswordPage"
                class="font-medium text-indigo-600 hover:text-indigo-500">
                Login With Your Temporary Password
            </button>
        </div>
    </div>

    <div v-if="showMFAInput" id="mfa_verification" class="w-4/12 mx-auto mt-8 border border-gray-300 p-8 rounded-md">
        <div class="flex flex-col items-center mt-10">
            <img src="../assets/GM_Digital_Doctor_Logo_RGB_150dpi.png" alt="Guard.me logo" class="h-24 mb-4">
            <h2 class="text-2xl font-bold mb-4">Enter Verification Code</h2>
            <p>We have sent a verification code by email to {{ maskedEmail }}</p>
            <div class="flex space-x-2 mb-6">
                <input v-for="(digit, index) in mfaCode" :key="index" type="text" maxlength="1" v-model="mfaCode[index]"
                    @input="focusNext(index)" @keydown.backspace="focusPrevious($event, index)" :ref="'digit' + index"
                    class="w-12 h-12 text-center text-xl border border-gray-300 rounded" />
            </div>

            <div class="mt-4">
                <span class="text-blue-900 mr-2">Didn't receive the code?</span>
                <a @click.prevent="resendCode" class="text-blue-900 hover:underline">Resend Code</a>
            </div>

            <div class="flex space-x-4 mt-6 mb-6">
                <button @click="cancelMfa"
                    class="bg-white text-blue-900 border-2 border-blue-900 px-4 py-2 rounded hover:bg-blue-100 w-24">
                    Cancel
                </button>
                <button @click="verifyCode" :disabled="!isMfaCodeComplete"
                    class="bg-blue-900 text-white border-2 border-blue-900 px-4 py-2 rounded hover:bg-blue-900 w-24 disabled:opacity-50 disabled:cursor-not-allowed">
                    Verify
                </button>
            </div>

            <div v-if="infoMessage && !errorMessage"
                class="mb-4 p-2 text-center text-green-600 bg-green-100 border border-green-300 rounded">
                {{ infoMessage }}
            </div>
            <div v-if="errorMessage" class="mb-4 p-2 text-center text-red-600 bg-red-100 border border-red-300 rounded">
                {{ errorMessage }}
            </div>

        </div>
    </div>

    <div v-if="showVerifyRegistration" class="w-4/12 mx-auto mt-8 border border-gray-300 p-8 rounded-md">
        <h2 class="text-3xl font-semibold mb-6 text-center">Verify Registration</h2>
        <form @submit.prevent="verifyRegistration" class="space-y-4">
            <div>
                <label for="email" class="block text-sm font-medium text-gray-600">Email</label>
                <input v-model="email" id="email" name="email" type="email" class="mt-1 p-2 w-full border rounded-md">
            </div>

            <div>
                <label for="confirmationCode" class="block text-sm font-medium text-gray-600">Confirmation Code</label>
                <input v-model="confirmationCode" id="confirmationCode" name="confirmationCode"
                    class="mt-1 p-2 w-full border rounded-md">
            </div>

            <div class="text-sm">
                <button @click.prevent="hideVerifyRegistrationForm"
                    class="font-medium text-indigo-600 hover:text-indigo-500">
                    Go to Login
                </button>
            </div>

            <div>
                <button type="submit"
                    class="w-full p-2 bg-blue-500 text-white rounded-md hover:bg-blue-600 focus:outline-none focus:ring focus:border-blue-300">
                    Verify
                </button>
            </div>

            <div v-if="infoMessage && !errorMessage"
                class="mb-4 p-2 text-center text-green-600 bg-green-100 border border-green-300 rounded">
                {{ infoMessage }}
            </div>
            <div v-if="errorMessage" class="mb-4 p-2 text-center text-red-600 bg-red-100 border border-red-300 rounded">
                {{ errorMessage }}
            </div>
        </form>
    </div>
</template>

<script>
import axios from 'axios';


export default {
    created() {
        this.infoMessage = this.$store.state.showTokenExpiringMessage ?? null;
    },
    beforeRouteLeave() {
        this.$store.commit('setShowTokenExpiringMessage', null);
    },
    data() {
        return {
            email: '',
            password: '',
            rememberMe: false,
            userToken: '',
            refreshToken: '',
            userId: '',
            userName: '',
            apiUrl: process.env.VUE_APP_API_BASE_URL,
            mfaVerified: false,
            showMFAInput: false,
            fullMfaCode: '',
            infoMessage: null,
            errorMessage: '',
            showVerifyRegistration: false,
            mfaCode: ['', '', '', '', '', ''],
            maskedEmail: '',
            loginClicked: false,
        };
    },
    computed: {
        isMfaCodeComplete() {
            return this.mfaCode.every(digit => digit.length === 1);
        },
    },
    methods: {
        resendCode() {
            //need to re-trigger login
            this.loginWithChallenge();
        },
        async verifyCode() {
            this.fullMfaCode = this.mfaCode.join('');
            const session = this.$store.state.session;

            await axios.post(this.apiUrl + `/api/cognito/login/verify`, {
                email: this.email,
                code: this.fullMfaCode,
                session: session,
            }).then(async (response) => {
                // this.mfaVerified = true;
                // this.showMFAInput = false;
                // this.errorMessage = '';
                this.infoMessage = 'MFA code verified';
                // Save the user token
                this.userToken = response.data.access_token;
                this.refreshToken = response.data.refresh_token;
                this.$store.commit('setUserToken', this.userToken);
                this.$store.commit('setRefreshToken', this.refreshToken);

                this.$emit('update-show-login', false);
                this.$emit('update-show-profile', true);

                await this.getUserName();
                // Redirect to the dashboard page
                this.$router.push('/dashboard');

            }).catch((error) => {
                console.error('Failed to verify MFA:', error.response || error);
                this.errorMessage = error.response?.data?.message;
                //reset the session
                this.$store.commit('removeSession');
                const newSession = error.response?.data?.Session ?? '';
                this.$store.commit('setSession', newSession);
            });
        },
        cancelMfa() {
            this.showMFAInput = false;
            this.loginClicked = false;
            this.mfaCode = ['', '', '', '', '', ''];

            //clear messages
            this.errorMessage = '';
            this.infoMessage = '';
        },
        focusNext(index) {
            if (this.mfaCode[index].length === 1 && index < 5) {
                this.$refs[`digit${index + 1}`][0].focus();
            }
        },
        focusPrevious(event, index) {
            if (event.key === 'Backspace' && this.mfaCode[index] === '' && index > 0) {
                this.$refs[`digit${index - 1}`][0].focus();
            }
        },
        redirectToForgetPasswordPage() {
            this.$router.push('/forgot-password');
        },
        redirectToResetPasswordPage() {
            this.$router.push('/login-temp-password');
        },
        showVerifyRegistrationForm() {
            this.showVerifyRegistration = true;
        },
        hideVerifyRegistrationForm() {
            this.showVerifyRegistration = false;
        },
        async verifyRegistration() {
            await axios.post(this.apiUrl + `/api/cognito/signup/confirm`, {
                email: this.email,
                code: this.confirmationCode,
            }).then((response) => {
                this.errorMessage = '';
                this.infoMessage = response.data.message ? response.data.message : 'Registration verified';
            }).catch((error) => {
                console.error('Failed to verify registration:', error.response || error);
                this.errorMessage = error.response.data.message;
            });
        },
        async login() {
            if (!this.mfaVerified) {
                this.showMFAInput = true;
            } else {
                await this.regularLogin();
            }
        },
        async getUserName() {
            const token = this.$store.state.userToken;
            const config = {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            };
            await axios.get(this.apiUrl + '/api/accounts/username', config)
                .then(response => {
                    this.userName = response.data.username;
                    this.$store.commit('setUserName', this.userName);
                    this.$emit('update-user-name', this.userName);
                })
                .catch(error => {
                    console.error('There was an error!', error);
                });
        },
        async loginWithChallenge() {
            //clear error message
            this.errorMessage = '';
            //set info message
            this.infoMessage = 'We are sending a verification code to your email. Please wait...';
            //disable login button
            this.loginClicked = true;


            // axios.defaults.withXSRFToken = true;
            await axios.post(this.apiUrl + `/api/cognito/login`, {
                email: this.email,
                password: this.password,
                authChallengeType: "email"
            }).then((response) => {
                //if response.data contains ChallengeName, then it is an error
                if (response.data.ChallengeName && response.data.ChallengeName == "NEW_PASSWORD_REQUIRED") {
                    console.error('ChallengeName detected:', response.data.ChallengeName);
                    this.errorMessage = "You need to reset your password upon first login.";
                    this.$router.push('/login-temp-password');
                    return;
                }

        const emailFromApiResponse = response.data.ChallengeParameters.email;
        this.maskedEmail = emailFromApiResponse.replace(/.(?=.{1,}@)/g, '*');
        this.showMFAInput = true;
        //clear error/inf messages
        this.errorMessage = '';
        this.infoMessage = '';


                //set session
                this.$store.commit('setSession', response.data.Session);
            }).catch((error) => {
                this.errorMessage = error.response.data.message;
                console.error('Failed to login:', error.response || error);

                //reactivate login button and clear info
                this.loginClicked = false;
                this.infoMessage = '';
            });
        },
        async regularLogin() {
            axios.defaults.withXSRFToken = true;
            await axios.post(this.apiUrl + `/api/cognito/login`, {
                email: this.email,
                password: this.password,
                authChallengeType: "none"
            }).then((response) => {
                //if response.data contains ChallengeName, then it is an error
                if (response.data.ChallengeName && response.data.ChallengeName == "NEW_PASSWORD_REQUIRED") {
                    console.error('ChallengeName detected:', response.data.ChallengeName);
                    this.errorMessage = "You need to reset your password upon first login.";
                    this.$router.push('/login-temp-password');
                    return;
                }

                //on finish reset password
                this.password = '';
                this.$emit('update-show-login', false);
                this.$emit('update-show-profile', true);

                // Save the user token
                this.userToken = response.data.access_token;
                this.refreshToken = response.data.refresh_token;
                this.$store.commit('setUserToken', this.userToken);
                this.$store.commit('setRefreshToken', this.refreshToken);

                this.getUserName();
                // Redirect to the dashboard page
                this.$router.push('/dashboard');
            }).catch((error) => {
                this.errorMessage = error.response.data.message;
                console.error('Failed to login:', error.response || error);
            });
        },
        async fetchCsrfToken() {
            axios.get(this.apiUrl + '/sanctum/csrf-cookie').then((response) => {
                axios.defaults.headers.common['X-XSRF-TOKEN'] = response.headers['x-csrf-token'];

            }).catch((error) => {
                console.error('Failed to fetch CSRF token:', error.response || error);
                throw error;
            });
        },
        async verifyMFA() {
            axios.defaults.withCredentials = true;
            await axios.post(this.apiUrl + `/api/mfa/verify`, {
                email: this.email,
                token: this.fullMfaCode,
            }).then(() => {
                this.mfaVerified = true;
                this.showMFAInput = false;
                this.errorMessage = '';
                this.infoMessage = 'MFA code verified';
                this.regularLogin();
            }).catch((error) => {
                console.error('Failed to verify MFA:', error.response || error);
                console.error(error.response.data.message);
                this.errorMessage = error.response.data.message;
            });
        },
        async sendMFA() {
            axios.defaults.withCredentials = true;
            await axios.post(this.apiUrl + `/api/mfa/send`, {
                email: this.email,
            }).then(() => {
                this.errorMessage = '';
                this.infoMessage = 'MFA code sent to your email';
            }).catch((error) => {
                console.error('Failed to send MFA:', error.response || error);
                this.errorMessage = 'Failed to send MFA code';
            });
        },
    },
    mounted() {
        this.$emit('update-show-login', true);
        this.$emit('update-show-profile', false);
        this.$emit('update-user-name', '');
    },


};
</script>
