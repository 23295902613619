import axios from "axios";

const TabService = {
    async listUserTabPermissions(config) {
        const backendUrl = process.env.VUE_APP_API_BASE_URL;
        try {
            const response = await axios.get(backendUrl + '/api/permissions/tabs', config);
            this.tabs = response.data;
        } catch (error) {
            console.error('Error loading permissions!', error);
            //if status is 401, redirect to login
            if (error.response.status === 401) {
                this.$store.commit('setUserToken', '');
                this.$store.commit('setUserName', '');
                this.$store.commit('setShowTokenExpiringMessage', "You don't have permissions to access the dashboard.");
                this.$router.push('/login');
            }
        }
        return this.tabs;
    },
    async getFirstAvailableTabUrl(config) {
        if (!this.tabs) {
            await this.listUserTabPermissions(config);
        }

        if (!this.tabs) {
            return '/';
        }

        if (this.tabs.includes('files')) {
            return '/dashboard/files';
        } else if (this.tabs.includes('schools')) {
            return '/dashboard/schools';
        } else if (this.tabs.includes('users')) {
            return '/dashboard/users';
        } else if (this.tabs.includes('upload')) {
            return '/dashboard/upload';
        } else if (this.tabs.includes('api')) {
            return '/dashboard/eligibility-check';
        } else if (this.tabs.includes('emails')) {
            return '/dashboard/emails';
        } else if (this.tabs.includes('docs')) {
            return '/dashboard/docs';
        }
        return '/';
    },
}


export default TabService;
