<template>
  <div class="w-full max-w-sm mx-auto mt-8 bg-white p-6 rounded-md shadow-md">
    <h2 class="text-2xl font-bold mb-4">{{ step === 1 ? 'Forgot Password' : 'Reset Password' }}</h2>

    <!-- First Step: Enter Email -->
    <div v-if="step === 1">
      <form @submit.prevent="requestReset">
        <div class="mb-4">
          <label for="email" class="block text-gray-700">Email Address:</label>
          <input
              type="email"
              id="email"
              v-model="form.email"
              class="w-full px-4 py-2 border border-gray-300 rounded-md focus:outline-none focus:border-blue-500"
              required
          />
        </div>

        <button
            type="submit"
            class="w-full bg-blue-500 text-white py-2 rounded-md hover:bg-blue-600 focus:outline-none focus:bg-blue-600"
        >
          Request Reset
        </button>
      </form>
    </div>

    <!-- Second Step: Enter Code, New Password, and Confirmation -->
    <div v-if="step === 2">
      <form @submit.prevent="resetPassword">
        <div class="mb-4">
          <label for="email" class="block text-gray-700">Email Address:</label>
          <input
              type="email"
              id="email"
              v-model="form.email"
              class="w-full px-4 py-2 border border-gray-300 rounded-md bg-gray-100 text-gray-500"
              required
              readonly
          />
        </div>
        <div class="mb-4">
          <label for="reset_code" class="block text-gray-700">Reset Code:</label>
          <input
              type="text"
              id="reset_code"
              v-model="form.reset_code"
              class="w-full px-4 py-2 border border-gray-300 rounded-md focus:outline-none focus:border-blue-500"
              required
          />
        </div>

        <div class="mb-4">
          <label for="new_password" class="block text-gray-700">New Password:</label>
          <input
              type="password"
              id="new_password"
              v-model="form.new_password"
              @input="validatePassword()"
              @focus="showTooltip = true"
              @blur="showTooltip = false; validatePassword()"
              class="w-full px-4 py-2 border border-gray-300 rounded-md focus:outline-none focus:border-blue-500"
              required
          />
        </div>

        <div v-if="showTooltip" class="absolute bg-white border border-gray-300 p-2 rounded shadow-md text-sm z-10 ">
          <ul class="text-gray-600">
            <li :class="{ 'text-green-600': validLength }">At least 8 characters</li>
            <li :class="{ 'text-green-600': hasUppercase }">At least 1 uppercase letter</li>
            <li :class="{ 'text-green-600': hasLowercase }">At least 1 lowercase letter</li>
            <li :class="{ 'text-green-600': hasSpecialCharacter }">At least 1 special character  (@$!%*#?&)</li>
            <li :class="{ 'text-green-600': hasNumeric }">At least 1 numeric character</li>
          </ul>
        </div>

        <div class="mb-4">
          <label for="password_confirmation" class="block text-gray-700">Confirm New Password:</label>
          <input
              type="password"
              id="password_confirmation"
              v-model="form.password_confirmation"
              class="w-full px-4 py-2 border border-gray-300 rounded-md focus:outline-none focus:border-blue-500"
              required
          />
        </div>

        <button
            type="submit"
            :disabled="!isPasswordValid"
            class="w-full bg-blue-500 text-white py-2 rounded-md hover:bg-blue-600 focus:outline-none focus:bg-blue-600 disabled:opacity-50 disabled:cursor-not-allowed"
        >
          Reset Password
        </button>

        <div class="text-sm">
          <button
              @click.prevent="redirectToLoginPage"
              class="font-medium text-indigo-600 hover:text-indigo-500"
          >
            Go to Login
          </button>
        </div>
      </form>
    </div>

    <!-- Error or Success Messages -->
    <div v-if="message" class="mt-4">
      <p :class="{ 'text-green-600': success, 'text-red-600': !success }">{{ message }}</p>
    </div>
  </div>
</template>

<script>
import axios from "axios";

export default {
  data() {
    return {
      apiUrl: process.env.VUE_APP_API_BASE_URL,
      step: 1, // Controls which step is shown
      form: {
        email: '',
        reset_code: '',
        new_password: '',
        password_confirmation: '',
      },
      message: '',
      success: false,
      showTooltip: false,
      validLength: false,
      hasUppercase: false,
      hasLowercase: false,
      hasNumeric: false,
      hasSpecialCharacter: false,
    };
  },
  computed: {
    isPasswordValid() {
      return (
          this.validLength &&
          this.hasUppercase &&
          this.hasLowercase &&
          this.hasSpecialCharacter &&
          this.hasNumeric
      );
    },
  },
  methods: {
    getApiConfig() {
      axios.defaults.withCredentials = true;
      axios.defaults.withXSRFToken = true;
      const token = this.$store.state.userToken;

      return {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      };
    },
    redirectToLoginPage() {
      this.$router.push('/login');
    },
    requestReset() {
      if (!this.getApiConfig()) {
        return;
      }

      const config = this.getApiConfig();
      axios.post(this.apiUrl + '/api/cognito/forgot-password', {
        email: this.form.email,
      }, config)
          .then(() => {
            this.step = 2; // Move to the next step
            this.message = 'Check your email for the reset code.';
            this.success = true;
            //do not let new password auto filled
            this.form.new_password = '';
            this.form.password_confirmation = '';
          })
          .catch((error) => {
            this.message = error.response.data.message;
            this.success = false;
          });
    },
    resetPassword() {
      if (this.form.new_password !== this.form.password_confirmation) {
        this.message = "Passwords don't match!";
        this.success = false;
        return;
      }

      if (!this.getApiConfig()) {
        return;
      }

      // Call the API to reset the password
      const config = this.getApiConfig();
      axios.post(this.apiUrl + '/api/cognito/forgot-password/confirm', {
        email: this.form.email,
        code: this.form.reset_code,
        password: this.form.new_password,
        password_confirmation: this.form.password_confirmation,
      }, config)
        .then(response => {
          this.message = response.data.message ? response.data.message : 'Password reset successfully!';
          this.success = true;
        })
        .catch(error => {
          console.error('There was an error!', error);
          this.message = 'Password reset failed!';
          this.success = false;
        });
    },
    validatePassword() {
      const password = this.form.new_password;

      this.validLength = password.length >= 8;
      this.hasUppercase = /[A-Z]/.test(password);
      this.hasLowercase = /[a-z]/.test(password);
      this.hasSpecialCharacter = /[@$!%*#?&]/.test(password);
      this.hasNumeric = /\d/.test(password);
    },
  },
};
</script>

<style scoped>
/* Additional Tailwind CSS-based styles if needed */
</style>
