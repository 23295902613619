// import Vue from 'vue';
import { createApp } from 'vue';
import App from './App.vue';
import store from './store';
import { createRouter, createWebHistory } from 'vue-router';
import '../css/app.css';
import Login from '@/components/DDLoginForm.vue';
import VerifyRegistration from '@/components/DDVerifyRegistration.vue';
import DDLoginWithTempPassword from "@/components/DDLoginWithTempPassword.vue";
import Home from '@/components/DDHomeInfo.vue';
import Dashboard from "@/components/DDDashboard.vue";
import DDFilesTab from "@/components/DDFilesTab.vue";
import DDUploadTab from "@/components/DDUploadTab.vue";
import DDApiTab from "@/components/DDApiTab.vue";
import DDSchoolsTab from "@/components/DDSchoolsTab.vue";
import DDUsersTab from "@/components/DDUsersTab.vue";
import DDEmailsTab from "@/components/DDEmailsTab.vue";
import DDDocumentTab from "@/components/DDDocumentTab.vue";
import DDProfile from "@/components/DDProfile.vue";
import DDForgetPassword from "@/components/DDForgetPassword.vue";
import DDPrivacyNotice from "@/components/DDPrivacyNotice.vue";
import DDTermOfUse from "@/components/DDTermOfUse.vue";
import DDCookieNotice from "@/components/DDCookieNotice.vue";

window.Vapor = require('laravel-vapor');


const routes = [
    {path: '/', component: Home, name: 'home'},
    {path: '/login', component: Login, name: 'login'},
    {path: '/verify_registration', component: VerifyRegistration, name: 'Verify Registration'},
    {path: '/login-temp-password', component: DDLoginWithTempPassword, name: 'Login With Temp Password'},
    {path: '/forgot-password', component: DDForgetPassword, name: 'Forget Password'},
    {path: '/profile', component: DDProfile, name: 'profile'},
    {
        path: '/dashboard', component: Dashboard, name: 'dashboard', meta: { requiresAuth: true },
        children: [
            {
                path: 'files',
                component: DDFilesTab,
                name: 'files',
                meta: { requiresAuth: true }
            },
            {
                path: 'upload',
                component: DDUploadTab,
                name: 'upload',
                meta: { requiresAuth: true }
            },
            {
                path: 'eligibility-check',
                component: DDApiTab,
                name: 'eligibility-check',
                meta: {requiresAuth: true}
            },
            {
                path: 'schools',
                component: DDSchoolsTab,
                name: 'schools',
                meta: { requiresAuth: true }
            },
            {
                path: 'users',
                component: DDUsersTab,
                name: 'users',
                meta: { requiresAuth: true }
            },
            {
                path: 'emails',
                component: DDEmailsTab,
                name: 'emails',
                meta: { requiresAuth: true }
            },
            {
                path: 'docs',
                component: DDDocumentTab,
                name: 'docs',
                meta: { requiresAuth: true }
            },
            {
                path: '/:catchAll(.*)',
                component: Home,
                name: 'home',
            },
        ],
    },
    { path: '/privacy-notice', component: DDPrivacyNotice, name: 'Privacy Notice' },
    { path: '/terms-of-use', component: DDTermOfUse, name: 'Term Of Use' },
    { path: '/cookie-notice', component: DDCookieNotice, name: 'Cookie Notice' },


];

const router = createRouter({
    history: createWebHistory(),
    routes,
});

router.beforeEach((to, from, next) => {
    if (to.matched.some(record => record.meta.requiresAuth)) {
        // this route requires auth, check if logged in, token should be in vuex store
        // if not, redirect to login page.
        const token = store.getters.userToken;
        if (!token) {
            next(
                {
                    name: 'login',
                });
        } else {
            next();
        }
    } else {
        next(); // make sure to always call next()!
    }
});

const app = createApp(App);
app.use(router);
app.use(store);
app.mount('#app');
