<script>
export default {
  name: 'DDHomeInfo',
  components: {},
  data() {
    return {
      files: [],
      apiUrl: process.env.VUE_APP_API_BASE_URL,
    };
  },
  mounted() {
    //redirect to login or dashboard to hide this page
    this.$router.push('/dashboard');
  },
  methods: {
  },
};
</script>

<template>
  <div class="flex items-center justify-center h-screen">
    <div class="text-center">
      <h1 class="text-4xl font-bold text-gray-800 mb-4">Welcome to Guard.me Easy Care Student Management</h1>
    </div>
  </div>
</template>


<style scoped>

</style>
