<template>
  <div id="otnotice-81ebf89b-b52b-4e2b-be6a-59fae21630de" class="otnotice mt-4"></div>
  <div id="otnotice-57fc01e3-0d85-45b3-b457-1d130a1fb333" class="otnotice mt-4 invisible"></div>

</template>

<script>
export default {
  mounted() {
    this.loadPrivacyNoticeScript();
    this.loadTermOfUseScript();
  },
  methods: {
    loadPrivacyNoticeScript() {
      // Load OneTrust privacy notice
      const script1 = document.createElement('script');
      script1.src = 'https://privacyportal-ca-cdn.onetrust.com/privacy-notice-scripts/otnotice-1.0.min.js';
      script1.setAttribute('type', 'text/javascript');
      script1.setAttribute('charset', 'UTF-8');
      script1.setAttribute('id', 'otprivacy-notice-script');
      script1.setAttribute('settings', 'eyJjYWxsYmFja1VybCI6Imh0dHBzOi8vcHJpdmFjeXBvcnRhbC1jYS5vbmV0cnVzdC5jb20vcmVxdWVzdC92MS9wcml2YWN5Tm90aWNlcy9zdGF0cy92aWV3cyJ9');
      script1.onload = () => {
        const script2 = document.createElement('script');
        script2.type = 'text/javascript';
        script2.charset = 'UTF-8';
        script2.text = `
        // To ensure external settings are loaded, use the Initialized promise:
        OneTrust.NoticeApi.Initialized.then(function() {
          OneTrust.NoticeApi.LoadNotices(["https://privacyportal-ca-cdn.onetrust.com/96c2069e-5f80-46fc-b63d-df375e98b318/privacy-notices/81ebf89b-b52b-4e2b-be6a-59fae21630de.json"]);
        });
      `;
        document.body.appendChild(script2);
      };
      document.body.appendChild(script1);
    },
    loadTermOfUseScript(){
      const script1 = document.createElement('script');
      script1.src = 'https://privacyportal-ca-cdn.onetrust.com/privacy-notice-scripts/otnotice-1.0.min.js';
      script1.setAttribute('type', 'text/javascript');
      script1.setAttribute('charset', 'UTF-8');
      script1.setAttribute('id', 'otprivacy-notice-script');
      script1.setAttribute('settings', 'eyJjYWxsYmFja1VybCI6Imh0dHBzOi8vcHJpdmFjeXBvcnRhbC1jYS5vbmV0cnVzdC5jb20vcmVxdWVzdC92MS9wcml2YWN5Tm90aWNlcy9zdGF0cy92aWV3cyJ9');
      script1.onload = () => {
        const script2 = document.createElement('script');
        script2.type = 'text/javascript';
        script2.charset = 'UTF-8';
        script2.text = `
        // To ensure external settings are loaded, use the Initialized promise:
        OneTrust.NoticeApi.Initialized.then(function() {
          OneTrust.NoticeApi.LoadNotices(["https://privacyportal-ca-cdn.onetrust.com/96c2069e-5f80-46fc-b63d-df375e98b318/privacy-notices/57fc01e3-0d85-45b3-b457-1d130a1fb333.json"]);
        });
      `;
        document.body.appendChild(script2);
      };
      document.body.appendChild(script1);
    },
  }
};
</script>

<style scoped>

</style>