<template>
    <div class="w-4/12 mx-auto mt-8 border border-gray-300 p-8 rounded-md">
      <h2 class="text-3xl font-semibold mb-6 text-center">Verify Registration</h2>
      <form @submit.prevent="verifyRegistration" class="space-y-4">
        <div>
          <label for="email" class="block text-sm font-medium text-gray-600">Email</label>
          <input v-model="email" id="email" name="email" type="email" class="mt-1 p-2 w-full border rounded-md">
        </div>

        <div>
          <label for="confirmationCode" class="block text-sm font-medium text-gray-600">Confirmation Code</label>
          <input v-model="confirmationCode" id="confirmationCode" name="confirmationCode"
                 class="mt-1 p-2 w-full border rounded-md">
        </div>

        <div class="text-sm">
          <button
              @click.prevent="redirectToLoginPage"
              class="font-medium text-indigo-600 hover:text-indigo-500"
          >
            Go to Login
          </button>
        </div>

        <div>
          <button type="submit"
                  class="w-full p-2 bg-blue-500 text-white rounded-md hover:bg-blue-600 focus:outline-none focus:ring focus:border-blue-300">
            Verify
          </button>
        </div>

        <div v-if="infoMessage && !errorMessage"
             class="mb-4 p-2 text-center text-green-600 bg-green-100 border border-green-300 rounded">
          {{ infoMessage }}
        </div>
        <div v-if="errorMessage" class="mb-4 p-2 text-center text-red-600 bg-red-100 border border-red-300 rounded">
          {{ errorMessage }}
        </div>
      </form>
    </div>
</template>

<script>
import axios from 'axios';

export default {
  data() {
    return {
      email: '',
      confirmationCode: '',
      infoMessage: '',
      errorMessage: '',
      newPassword: '',
      confirmPassword: '',
    };
  },
  methods: {
    redirectToLoginPage() {
      // Redirect to the login page
      this.$router.push('/login');
    },
    async verifyRegistration() {
      this.errorMessage = '';
      this.infoMessage = '';
      await axios.post(this.apiUrl + `/api/cognito/signup/confirm`, {
        email: this.email,
        code: this.confirmationCode,
      }).then(() => {
        this.errorMessage = '';
        this.infoMessage = 'Registration verified';
      }).catch((error) => {
        console.error('Failed to verify registration:', error.response || error);
        this.errorMessage = error.response.data.message;
      });
    },
  },
  props: {
    showVerifyRegistration: {
      type: Boolean,
      required: true,
    },
  },
  computed: {
    apiUrl() {
      return process.env.VUE_APP_API_BASE_URL;
    },
  },
};

</script>



<style scoped>

</style>