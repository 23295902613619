<template>
    <div v-show="showDocsTab">
        <div id="swagger-ui"></div>
    </div>
</template>

<script>
import axios from 'axios';
import SwaggerUI from 'swagger-ui';
import 'swagger-ui/dist/swagger-ui.css';
import AuthenticationService from "@/services/AuthenticationService";

export default {
    props: {
        showDocsTab: {
            type: Boolean,
            required: true,
        }
    },
    data() {
        return {
            apiUrl: process.env.VUE_APP_API_BASE_URL,
        };
    },
    mounted() {
        this.getDocs();
    },
    methods: {
        async getDocs() {
            const token = this.$store.state.userToken;
            const config = {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            };
            try {
                const response = await axios.get(`${this.apiUrl}/api/documents/maple`, config);
                const swaggerDoc = response.data;
                this.initializeSwaggerUI(swaggerDoc);
            } catch (error) {
                console.error('Error fetching Swagger documentation:', error);
            }
        },
        initializeSwaggerUI(swaggerDoc) {
            // Initialize Swagger UI with the fetched documentation
            SwaggerUI({
                dom_id: '#swagger-ui',
                spec: swaggerDoc,
                responseInterceptor: (response) => {
                    const message = response?.body?.message;
                    if (response.status == 403 && message == 'User access is disabled by the admin') {
                        return AuthenticationService.disable(this.$store, this.$router)
                    }
                    return response;
                },
                // Other options
            });
        },
    },
};
</script>

<style scoped>
/* Add any scoped styles if needed */
</style>
