<template>
  <div class="rounded border border-gray-300 p-6 w-3/4 mt-1">
    <h2 class="text-xl font-bold mb-4">Error Summary</h2>

    <div v-for="(error, key) in errors" :key="key" class="mb-6">
      <div @click="toggleError(key)" class="flex items-center cursor-pointer border-b border-gray-300 pb-2 mb-2">
        <h3 class="text-lg font-semibold flex-grow">{{ error.description }}</h3>

        <span class="text-gray-600" v-if="expandedError !== key">&#9662;</span> <!-- Down arrow icon when collapsed -->
        <span class="text-gray-600" v-else>&#9652;</span> <!-- Up arrow icon when expanded -->
      </div>

      <div v-show="expandedError === key" class="border border-gray-300 p-4 rounded">
        <div v-if="error.code === 'E2003'" >
          <ul v-if="error.data && error.data.length > 0" class="">
            <li v-for="(item, index) in error.data" :key="index"  class="mb-2 border border-gray-300 border-dotted">
              <p><span class="font-semibold">School:</span> {{ item.school_alias }}, <span class="font-semibold">Student Identifier:</span> {{ item.student_identifier }}</p>
              <ul class="">
                <li v-for="(row) in item.ambiguous_rows" :key="row.row" class="ml-4"> Row: {{ row.row }}</li>
              </ul>
              <span v-if="item.rows > 5" class="mt-2 text-red-600">There are a total of {{item.rows}} rows with this error.</span>
            </li>
          </ul>
        </div>

        <div v-else-if="error.code === 'E2001' || error.code === 'E2002'">
          <ul v-if="error.messages.length > 0" class="">
            <li v-for="(message, index) in error.messages" :key="index" class="mb-2">{{ message }}</li>
          </ul>
        </div>

        <!--        Header errors: E1001, E1002, E1003-->
        <div v-else-if="error.code === 'E1001' || error.code === 'E1002' || error.code === 'E1003'">
          <ul v-if="error.messages.length > 0" class="">
            <li v-for="(message, index) in error.messages" :key="index" class="mb-2">{{ message }}</li>
          </ul>
        </div>

        <div v-else-if="error.code === 'E2004'">
          <!--          Content Error E2004-->
          <div v-if="error.data.invalid_reporting_streams">
            <ul>
              <li v-for="(streams, school) in error.data.invalid_reporting_streams" :key="school" class="mb-2">
                <p><span class="font-semibold"
                         v-if="multipleSchoolForInvalidReportingStreams(error.data.invalid_reporting_streams)">{{
                    school
                  }}:</span></p>
                <p>The following Reporting Stream Names are invalid: <span
                    class="font-bold text-red-600">{{ streams.join(', ') }}</span></p>
                <p>Please choose from your valid Reporting Stream Names: <span
                    class="font-bold text-black">{{ error.data.options[school].join(', ') }}</span></p>
              </li>
            </ul>
          </div>
        </div>

        <p class="mt-2 text-red-600" v-if="error.rows > 5 && error.code !== 'E2003'"> There are a total of {{ error.rows }} rows with this error.</p>
        <p class="mt-2 text-red-600" v-if="error.rows > 5 && error.code === 'E2003'"> There are a total of {{ error.rows }} students with this error.</p>


        <p class="mt-4">{{ error.suggestion }}</p>
        <p class="">ErrorCode: {{ error.code }}</p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'DDErrorSummary',
  props: {
    errors: {
      required: true,
    },
  },
  data() {
    return {
      expandedError: null,
    };
  },
  mounted() {
  },
  methods: {
    toggleError(key) {
      this.expandedError = this.expandedError === key ? null : key;
    },
    multipleSchoolForInvalidReportingStreams($reportingStreamErrors) {
      return Object.keys($reportingStreamErrors).length > 1;
    }
  }
};
</script>
