<template>
  <div v-if="showUsersTab" class="pb-20">
    <div>
      <h2 class="text-2xl font-bold mb-4">Account Information</h2>

      <table class="table">
        <thead>
        <tr>
          <!--          <th>Id</th>-->
          <th>Profile Name</th>
          <th>Email</th>
          <!--          <th>Phone Number</th>-->
          <th>Password Status</th>
          <th>User Status</th>
          <th>Roles</th>
          <th>School</th>
          <th>Actions</th>
        </tr>
        </thead>
        <tbody>
        <tr v-for="(user, index) in users" :key="index">
          <!--          <td>{{ user.id }}</td>-->
          <td>{{ user.name }}</td>
          <td>{{ user.email }}</td>
          <!--          <td>{{  user.phone_number }}</td>-->
          <td>{{ user.status }}</td>
          <td>{{ user.is_disabled ? 'Inactive' : 'Active' }}</td>
          <td>{{ user.roles }}</td>
          <td>{{ user.school_name }}</td>
          <td>
            <button
                @click.prevent="resendTempPassword(user.email, user.id)"
                :disabled="user.status === 'CONFIRMED'"
                :class="[
                'py-2', 'px-6', 'rounded-md', 'focus:outline-none',
                user.status === 'CONFIRMED' ? 'bg-gray-400 text-gray-500 cursor-not-allowed' : 'bg-blue-500 text-white hover:bg-blue-600 focus:bg-blue-600',
              ]"
            >
              Resend Temp Password
            </button>

            <!--      resend result message-->
            <div v-if="resendTempPasswordMessage && selectedUser===user.id" class="mb-4">
              <p class="text-lg font-bold"
                 :class="{ 'text-green-600': isResendSuccess, 'text-red-600': !isResendSuccess }">
                {{ resendTempPasswordMessage }}
              </p>
            </div>

            <button
                v-if="user.school_name"
                @click.prevent="toggleSchoolModal(user.id, user.school_name)"
                :class="[
                'py-2', 'px-6', 'rounded-md', 'focus:outline-none', 'bg-blue-500 text-white hover:bg-blue-600 focus:bg-blue-600'
                ]"
            >
              Edit School
            </button>

            <div v-if="selectedUser===user.id && user.school_name && schoolModal && !schoolChangeMessage" class="mt-4">
              <!-- Dropdown for Selecting School -->
              <div class="mb-4">
                <label for="schoolName" class="block text-gray-700 text-sm font-bold mb-2">School:</label>
                <select
                    v-model="schoolNameSelected"
                    id="schoolDropdown"
                    class="w-full px-4 py-2 border border-gray-300 rounded-md focus:outline-none focus:border-blue-500"
                >
                  <option v-for="(schoolName, index) in schoolNames" :key="index">
                    {{ schoolName }}
                  </option>
                </select>
              </div>

              <!-- Submit Button to Confirm Change -->
              <button
                  @click="changeUserSchool(user.id)"
                  class="w-full bg-green-500 text-white py-2 rounded-md hover:bg-green-600 focus:outline-none focus:bg-green-600"
              >
                Change School
              </button>
            </div>
            <div v-if="selectedUser===user.id && user.school_name && schoolModal && schoolChangeMessage" class="mb-4">
              <p class="text-lg font-bold"
                 :class="{ 'text-green-600': isSchoolChangeSuccess, 'text-red-600': !isSchoolChangeSuccess }">
                {{ schoolChangeMessage }}
              </p>
            </div>

            <button
                v-if="user.note"
                @click.prevent="toggleNote(user.id, user.note)"
                :class="[
                'py-2', 'px-6', 'rounded-md', 'focus:outline-none', 'bg-blue-500 text-white hover:bg-blue-600 focus:bg-blue-600'
                ]"
            >
              Show Note
            </button>
            <div v-if="selectedUser===user.id && showNote" class="mb-4">
              <label for="schoolName" class="block text-gray-700 text-sm font-bold mb-2">Note:</label>
              <!--              note title and note content-->
              <div>
                <!--                <p class="text-lg font-bold">Title: {{ this.noteTitle }}</p>-->
                <!--                <p class="text-lg font-bold">{{ this.noteContent }}</p>-->
                <textarea
                    id="noteContent"
                    v-model="noteContent"
                    placeholder="Full Name, Phone Number, Additional Info."
                    class="w-full px-4 py-2 border border-gray-300 rounded-md focus:outline-none focus:border-blue-500"
                ></textarea>
                <button
                    v-if="user.note"
                    @click.prevent="editNote(user.note, noteContent)"
                    :class="[
                'py-2', 'px-6', 'rounded-md', 'focus:outline-none', 'bg-blue-500 text-white hover:bg-blue-600 focus:bg-blue-600'
                ]"
                >
                  <span>Edit Note</span>
                </button>

                <button
                    v-if="!user.note"
                    @click.prevent="createNote(user.id)"
                    :class="[
                'py-2', 'px-6', 'rounded-md', 'focus:outline-none', 'bg-blue-500 text-white hover:bg-blue-600 focus:bg-blue-600'
                ]"
                >
                  <span>Submit</span>
                </button>

              </div>
            </div>

            <div v-if="selectedUser===user.id && user.school_name && createNoteMessage" class="mt-4">
              <p class="text-lg font-bold"
                 :class="{ 'text-green-600': isCreateNoteSuccess, 'text-red-600': !isCreateNoteSuccess }">
                {{ createNoteMessage }}</p>
            </div>

            <button
                v-if="!user.note && !showNote && user.school_name"
                @click.prevent="toggleNote(user.id, user.note)"
                :class="[
                'py-2', 'px-6', 'rounded-md', 'focus:outline-none', 'bg-blue-500 text-white hover:bg-blue-600 focus:bg-blue-600'
                ]"
            >
              Create Note
            </button>

            <div v-if="selectedUser===user.id && user.school_name && editNoteMessage" class="mt-4">
              <p class="text-lg font-bold"
                 :class="{ 'text-green-600': isEditNoteSuccess, 'text-red-600': !isEditNoteSuccess }">
                {{ editNoteMessage }}</p>
            </div>

            <button
                @click.prevent="toggleEditUser(user.id, user.email, user.name, user.phone_number)"
                :class="[
                'py-2', 'px-6', 'rounded-md', 'focus:outline-none', 'bg-blue-500 text-white hover:bg-blue-600 focus:bg-blue-600'
                ]"
            >
              <span>Edit User</span>
            </button>

            <div v-if="showEditUser && selectedUser===user.id">
              <textarea
                  id="editUserName"
                  v-model="editUser.name"
                  placeholder="Name"
                  class="w-full px-4 py-2 border border-gray-300 rounded-md focus:outline-none focus:border-blue-500"
              ></textarea>

              <!--              <textarea-->
              <!--                  id="editUserPhone"-->
              <!--                  v-model="editUser.phone"-->
              <!--                  placeholder="Phone"-->
              <!--                  class="w-full px-4 py-2 border border-gray-300 rounded-md focus:outline-none focus:border-blue-500"-->
              <!--              ></textarea>-->

              <button
                  @click.prevent="editUserInfo()"
                  :class="[
                'py-2', 'px-6', 'rounded-md', 'focus:outline-none', 'bg-blue-500 text-white hover:bg-blue-600 focus:bg-blue-600'
                ]"
              >
                <span>Submit</span>
              </button>
            </div>

            <div v-if="editUserMessage && selectedUser===user.id" class="mb-4">
              <p class="text-lg font-bold"
                 :class="{ 'text-green-600': isEditUserSuccess, 'text-red-600': !isEditUserSuccess }">
                {{ editUserMessage }}
              </p>
            </div>

            <div v-if="user.is_disabled !== true">
              <button
                  @click.prevent="disableUser(user.email, user.id)"
                  :class="[
                'py-2', 'px-6', 'rounded-md', 'focus:outline-none', 'bg-blue-500 text-white hover:bg-blue-600 focus:bg-blue-600'
                ]"
              >
                <span>Disable User</span>
              </button>
              <div v-if="disableUserMessage && selectedUser === user.id" class="mb-4">
                <p class="text-lg font-bold"
                   :class="{ 'text-green-600': isDisableUserSuccess, 'text-red-600': !isDisableUserSuccess }">
                  {{ disableUserMessage }}
                </p>
              </div>
            </div>


          </td>
        </tr>
        </tbody>
      </table>


    </div>

    <button
        @click="toggleCreateAccount"
        class="bg-green-500 text-white py-2 px-6 rounded-md hover:bg-green-600 focus:outline-none focus:bg-green-600 mb-4 mt-8"
    >
      <i v-if="!showCreationModal">Create Account</i>
      <i v-else>Close</i>

    </button>


    <div v-if="showCreationModal" class="max-w-md mx-auto mt-8 p-4 bg-white shadow-md rounded-md">
      <h2 class="text-2xl font-bold mb-4">Create User Account</h2>

      <form @submit.prevent="createUser">
        <div class="mb-4">
          <label for="name" class="block text-gray-700 text-sm font-bold mb-2">Profile Name:</label>
          <input type="name" id="name" v-model="newUser.name" required
                 class="w-full px-4 py-2 border border-gray-300 rounded-md focus:outline-none focus:border-blue-500"/>
        </div>

        <div class="mb-4">
          <label for="email" class="block text-gray-700 text-sm font-bold mb-2">Email:</label>
          <input type="email" id="email" v-model="newUser.email" required
                 class="w-full px-4 py-2 border border-gray-300 rounded-md focus:outline-none focus:border-blue-500"/>
        </div>

        <div class="mb-4" v-if="newUser.type === 'school'">
          <label for="schoolName" class="block text-gray-700 text-sm font-bold mb-2">School:</label>
          <select v-model="schoolNameSelected" id="schoolDropdown" required>
            <option v-for="(schoolName, index) in schoolNames" :key="index">
              {{ schoolName }}
            </option>
          </select>
        </div>

        <div class="mb-4">
          <label for="userType" class="block text-gray-700 text-sm font-bold mb-2">User Type:</label>
          <select id="userType" v-model="newUser.type"
                  class="w-full px-4 py-2 border border-gray-300 rounded-md focus:outline-none focus:border-blue-500">
            <option value="customer_care">Customer Care</option>
            <!--            <option value="customer_care_manager">Customer Care Manager</option>-->
            <option value="account_manager">Account Manager</option>
            <option value="school">School User</option>
            <option value="doc">Document User</option>
            <option value="api">Api Consumer</option>
          </select>
        </div>

        <div class="mt-4" v-if="newUserCreateMessage">
          <p class="text-lg font-bold"
             :class="{ 'text-green-600': isCreationSuccess, 'text-red-600': !isCreationSuccess }">
            {{ newUserCreateMessage }}</p>
        </div>

        <button type="submit"
                class="bg-blue-500 text-white py-2 px-6 rounded-md hover:bg-blue-600 focus:outline-none focus:bg-blue-600">
          Create User
        </button>
      </form>
    </div>
  </div>

</template>

<script>
import axios from "axios";
import tokenService from "@/services/TokenService";
import AuthenticationService from "@/services/AuthenticationService";

export default {
  props: {
    showUsersTab: {
      type: Boolean,
      required: true,
    }
  },
  data() {
    return {
      apiUrl: process.env.VUE_APP_API_BASE_URL,
      users: [],
      newUser: {
        name: '',
        email: '',
        phone: '',
        schoolName: null,
        type: 'customer_care', // Default user type
      },
      editUser: {
        email: '',
        name: '',
        phone: '',
      },
      schoolNameSelected: "",
      schoolNames: [],
      schoolModal: false,
      showEditUser: false,
      newUserCreateMessage: '',
      resendTempPasswordMessage: '',
      schoolChangeMessage: '',
      editNoteMessage: '',
      disableUserMessage: '',
      editUserMessage: '',
      createNoteMessage: '',
      isResendSuccess: false,
      isCreationSuccess: false,
      isEditNoteSuccess: false,
      isEditUserSuccess: false,
      isCreateNoteSuccess: false,
      isSchoolChangeSuccess: false,
      isDisableUserSuccess: false,
      showCreationModal: false,
      selectedUser: null,
      showNote: false,
      noteTitle: '',
      noteContent: '',

    };
  },
  mounted() {
    this.getSchoolNames();
    this.listAccounts();
  },
  methods: {
    getApiConfig() {
      axios.defaults.withCredentials = true;
      const token = this.$store.state.userToken;
      if (tokenService.isTokenExpired(token)) {
        AuthenticationService.logout(this.$store, this.$router);
        return false;
      }
      return {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      };
    },
    clearMessages() {
      this.newUserCreateMessage = '';
      this.resendTempPasswordMessage = '';
      this.schoolChangeMessage = '';
      this.editNoteMessage = '';
      this.disableUserMessage = '';
      this.editUserMessage = '';
      this.createNoteMessage = '';
    },
    toggleOffAll() {
      this.showEditUser = false;
      this.schoolModal = false;
      this.showNote = false;
    },
    clearContent() {
      this.noteContent = '';
    },
    async disableUser($email, $userId) {
      this.clearMessages();
      this.selectedUser = $userId;
      if (!this.getApiConfig()) {
        return;
      }

      const config = this.getApiConfig();
      await axios.post(this.apiUrl + '/api/cognito/admin/users/access/disable',
          {
            email: $email,
          }
          , config)
          .then(() => {
            this.isDisableUserSuccess = true;
            this.disableUserMessage = 'User disabled successfully';
            //update the user list
            this.listAccounts();
          })
          .catch(error => {
            console.error('Error disabling user:', error?.response?.data);
            this.isDisableUserSuccess = false;
            this.disableUserMessage = error?.response?.data.message;
          });
    },
    editNote(noteId, noteContent) {
      this.clearMessages();
      if (!this.getApiConfig()) {
        return;
      }

      const config = this.getApiConfig();

      axios.post(this.apiUrl + '/api/notes/' + noteId, {
        title: 'User Note',
        content: noteContent,
      }, config)
          .then(response => {
            this.noteContent = response.data.content;
            this.isEditNoteSuccess = true;
            this.editNoteMessage = 'Note updated successfully';
          })
          .catch(error => {
            console.error('Error updating note:', error?.response?.data);
            this.editNoteMessage = error?.response?.data.message;
          });
    },
    createNote($userId) {
      this.clearMessages();
      if ($userId !== this.selectedUser) {
        this.noteContent = '';
      }

      this.isCreateNoteSuccess = false;
      this.createNoteMessage = '';

      this.selectedUser = $userId;
      this.showNote = true;
      if (!this.getApiConfig()) {
        return;
      }

      const config = this.getApiConfig();
      axios.post(this.apiUrl + '/api/notes', {
        parent_type: 'user_detail',
        parent_id: $userId,
        title: 'School User Note',
        content: this.noteContent,
      }, config)
          .then(response => {
            this.noteContent = response.data.content;
            this.isCreateNoteSuccess = true;
            this.createNoteMessage = 'Note created successfully';
            this.listAccounts();
            this.showNote = false;
          })
          .catch(error => {
            console.error('Error creating note:', error?.response?.data);
            this.createNoteMessage = error?.response?.data.message;
          });
    },
    toggleNote($userId, $noteId) {
      this.clearMessages();
      this.toggleOffAll();

      if ($userId !== this.selectedUser) {
        this.noteContent = '';
      }

      this.selectedUser = $userId;

      this.isEditNoteSuccess = false;
      this.editNoteMessage = '';

      if (!$noteId) {
        this.showNote = true;
        this.noteTitle = 'School User Note';
        this.noteContent = '';
        return;
      }

      this.showNote = !this.showNote;
      if (this.showNote) {
        if (!this.getApiConfig()) {
          return;
        }

        const config = this.getApiConfig();
        axios.get(this.apiUrl + '/api/notes/' + $noteId, config)
            .then(response => {
              this.noteTitle = response.data.title;
              this.noteContent = response.data.content;
            })
            .catch(error => {
              console.error('Error loading note: ' + error?.response?.data.message);
            });
      }
    },
    editUserInfo() {
      this.clearMessages();
      if (!this.getApiConfig()) {
        return;
      }

      const config = this.getApiConfig();
      axios.put(this.apiUrl + '/api/cognito/admin/users', {
        name: this.editUser.name,
        email: this.editUser.email,
        phone_number: this.editUser.phone,
      }, config)
          .then(() => {
            this.isEditUserSuccess = true;
            this.showEditUser = false;
            this.editUserMessage = 'User updated successfully';
            //update the user list
            this.listAccounts();
          })
          .catch((error) => {
            console.error('Error updating user:', error?.response?.data);
            this.isEditUserSuccess = false;
            this.editUserMessage = error?.response?.data.message;
          });
    },
    toggleEditUser($userId, $email, $name, $phone) {
      this.clearMessages();
      this.toggleOffAll();
      this.selectedUser = $userId;
      this.showEditUser = !this.showEditUser;
      this.editUser.name = $name;
      this.editUser.email = $email;
      this.editUser.phone = $phone;
      this.editUserMessage = '';
      this.isEditUserSuccess = false;
    },
    toggleSchoolModal($userId, $schoolName) {
      this.clearMessages();
      this.toggleOffAll();
      this.schoolNameSelected = $schoolName;
      this.selectedUser = $userId;
      this.schoolModal = !this.schoolModal;
      //clear the message
      this.schoolChangeMessage = '';
      this.isSchoolChangeSuccess = false;
    },
    toggleCreateAccount() {
      this.clearMessages();
      this.toggleOffAll();
      this.showCreationModal = !this.showCreationModal;
    },
    changeUserSchool($userId) {
      this.clearMessages();
      if (!this.getApiConfig()) {
        return;
      }

      const config = this.getApiConfig();
      axios.post(this.apiUrl + '/api/accounts/change_school',
          {
            user_id: $userId,
            school_name: this.schoolNameSelected,
          }
          , config)
          .then(() => {
            this.isSchoolChangeSuccess = true;
            this.schoolChangeMessage = 'School changed successfully';
            //update the user list
            this.listAccounts();
          })
          .catch(error => {
            console.error('Error changing school:', error?.response?.data);
            this.isSchoolChangeSuccess = false;
            this.schoolChangeMessage = error?.response?.data.message;
          });

    },
    async resendTempPassword($email, $userId) {
      this.clearMessages();
      this.selectedUser = $userId;
      if (!this.getApiConfig()) {
        return;
      }

      const config = this.getApiConfig();
      await axios.post(this.apiUrl + '/api/cognito/admin/users/resend-temporary-password',
          {
            email: $email,
          }
          , config)
          .then(response => {
            this.resendTempPasswordMessage = response.data.message;
            this.isResendSuccess = true;
          })
          .catch(error => {
            console.error('Error resending temp password:', error?.response?.data);
            this.resendTempPasswordMessage = error?.response?.data.message;
            this.isResendSuccess = false;
          });
    },
    async listAccounts() {
      if (!this.getApiConfig()) {
        return;
      }

      const config = this.getApiConfig();
      await axios.get(this.apiUrl + '/api/accounts', config)
          .then(response => {
            this.users = response.data.accounts;
          })
          .catch(error => {
            console.error('Error loading users: ' + error?.response?.data.message);
          });
    },
    async authorizeUserRole($role) {
      this.clearMessages();
      if (!this.getApiConfig()) {
        return;
      }

      const config = this.getApiConfig();
      await axios.post(this.apiUrl + '/api/permissions/authorize',
          {
            name: this.newUser.name,
            email: this.newUser.email,
            phone_number: "+11111111111",
            role: $role,
            school_name: this.newUser.schoolName,
          }
          , config)
          .then(() => {

          })
          .catch(error => {
            console.error('Error authorizing user role:', error?.response?.data);
          });
    },
    async createUser() {
      this.clearMessages();
      this.toggleOffAll();
      this.newUser.schoolName = this.schoolNameSelected;
      if (!this.getApiConfig()) {
        return;
      }

      const config = this.getApiConfig();
      await axios.post(this.apiUrl + '/api/cognito/admin/users',
          {
            name: this.newUser.name,
            email: this.newUser.email,
            phone_number: "+11111111111",
          }
          , config)
          .then(() => {
            //user type is school
            if (this.newUser.type === 'school') {
              this.authorizeUserRole('School User');
            } else if (this.newUser.type === 'customer_care') {
              this.authorizeUserRole('Customer Care User');
            } else if (this.newUser.type === 'account_manager') {
              this.authorizeUserRole('Account Manager');
            } else if (this.newUser.type === 'customer_care_manager') {
              this.authorizeUserRole('Customer Care Manager');
            } else if (this.newUser.type === 'doc') {
              this.authorizeUserRole('Doc User');
            } else if (this.newUser.type === 'api') {
              this.authorizeUserRole('API Consumer');
            }

            this.isCreationSuccess = true;
            this.newUserCreateMessage = 'User created successfully';

            // Reset the form
            this.newUser = {
              name: '',
              email: '',
              phone: '',
              schoolName: '',
              type: 'customer_care',
            };

            this.listAccounts();
          })
          .catch(error => {
            console.error('Error creating user:', error?.response?.data);
            this.isCreationSuccess = false;
            this.newUserCreateMessage = error?.response?.data.message;
          });
    },
    async getSchoolNames() {
      const token = this.$store.state.userToken;
      const config = {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      };
      await axios.get(this.apiUrl + '/api/schools', config)
          .then(response => {
            this.schoolNames = response.data.schools;
          })
          .catch(error => {
            console.error('Error loading School Names: ' + error?.response?.data.message);
          });
    },
  },
};
</script>

<style scoped>
.table {
  width: 100%;
  border-collapse: collapse;
}

.table th,
.table td {
  border: 1px solid #e2e8f0;
  padding: 8px;
  text-align: left;
}

.table th {
  background-color: #f7fafc;
}

.table tr:nth-child(even) {
  background-color: #edf2f7;
}

.table tr:hover {
  background-color: #cbd5e0;
}
</style>
