const AuthenticationService = {
    logout: (store, router, hideMessage) => {
        store.commit('removeUserName');
        store.commit('removeUserToken');
        store.commit('removeRefreshToken');
        store.commit('removeSession');
        if (!hideMessage) {
            store.commit('setShowTokenExpiringMessage', 'Your session expired. Please login to use the system.');
        }
        router.push('/login');
    },
    disable: (store, router) => {
        store.commit('setUserToken', 'disabled');
        store.commit('setUserName', '');
        store.commit('setShowTokenExpiringMessage', '');
        router.push('/login');
    }
}

export default AuthenticationService;
