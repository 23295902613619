<template>
  <div v-if="showFilesTab">
    <h2 class="text-2xl font-bold mb-4">File Information</h2>
    <table class="table">
      <thead>
      <tr>
        <th>File Id</th>
        <th>File Name</th>
        <th>File Status</th>
        <th>File Delete Status</th>
        <th>File Processed Student Count</th>
        <th>File Processed Date</th>
      </tr>
      </thead>
      <tbody>
      <tr v-for="(file, index) in files" :key="index">
        <td>{{ file.id }}</td>
        <td>{{ file.name }}</td>
        <td>{{ file.status }}</td>
        <td>{{ file.delete_status }}</td>
        <td>{{ file.students_count }}</td>
        <td>{{ file.processed_at }}</td>
      </tr>
      </tbody>
    </table>
  </div>
</template>

<script>

import axios from "axios";
import tokenService from "@/services/TokenService";
import AuthenticationService from "@/services/AuthenticationService";

export default {
  props: {
    showFilesTab: {
      type: Boolean,
      required: true,
    }
  },
  components: {},
  data() {
    return {
      files: [],
      apiUrl: process.env.VUE_APP_API_BASE_URL,
    };
  },
  mounted() {
    this.getFiles();
  },
  methods: {
    async getFiles() {
      const token = this.$store.state.userToken;
      if (tokenService.isTokenExpired(token)) {
        AuthenticationService.logout(this.$store, this.$router);
        return;
      }

      const config = {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      };
      await axios.get(this.apiUrl + '/api/files', config)
        .then(response => {
          this.files = response.data;
        })
        .catch(error => {
          console.error('There was an error!', error);
        });
    },
  },
};
</script>

<style scoped>
.table {
  width: 100%;
  border-collapse: collapse;
}

.table th,
.table td {
  border: 1px solid #e2e8f0;
  padding: 8px;
  text-align: left;
}

.table th {
  background-color: #f7fafc;
}

.table tr:nth-child(even) {
  background-color: #edf2f7;
}

.table tr:hover {
  background-color: #cbd5e0;
}
</style>
