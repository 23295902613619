/**
 * Vuex store for a Vue.js application.
 * This store manages the user's session information, including tokens and user name, in a centralized and predictable way.
 * @module store
 */

import { createStore } from 'vuex';

/**
 * Retrieve an item from local storage.
 * @param {string} key - The key of the item to retrieve.
 * @returns {string|null} The item from local storage, or null if the item does not exist.
 */
function getItem(key) {
    return localStorage.getItem(key) || null;
}

/**
 * Set an item in local storage.
 * @param {string} key - The key of the item to set.
 * @param {string} value - The value of the item to set.
 */
function setItem(key, value) {
    localStorage.setItem(key, value);
}

/**
 * Remove an item from local storage.
 * @param {string} key - The key of the item to remove.
 */
function removeItem(key) {
    localStorage.removeItem(key);
}

export default createStore({
    state: {
        userToken: getItem('userToken'),
        userName: getItem('userName'),
        refreshToken: getItem('refreshToken'),
        session: getItem('session'),
        showTokenExpiringMessage: null,
    },
    mutations: {
        setUserToken(state, token) {
            state.userToken = token;
            setItem('userToken', token);
        },
        removeUserToken(state) {
            state.userToken = null;
            removeItem('userToken');
        },
        setRefreshToken(state, token) {
            state.refreshToken = token;
            setItem('refreshToken', token);
        },
        removeRefreshToken(state) {
            state.refreshToken = null;
            removeItem('refreshToken');
        },
        setUserName(state, name) {
            state.userName = name;
            setItem('userName', name);
        },
        removeUserName(state) {
            state.userName = null;
            removeItem('userName');
        },
        setSession(state, session){
            state.session = session;
            setItem('session', session);
        },
        removeSession(state) {
            state.session = null;
            removeItem('session');
        },
        setShowTokenExpiringMessage(state, showMessage) {
            state.showTokenExpiringMessage = showMessage;
            setItem('showTokenExpiringMessage', showMessage);
        },
    },
    actions: {
        // Add other actions as needed
    },
    getters: {
        userToken: state => state.userToken,
        refreshToken: state => state.refreshToken,
        userName: state => state.userName,
        session: state => state.session,
        showTokenExpiringMessage: state => state.showTokenExpiringMessage,
    },
});
