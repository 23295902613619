<template>
  <div class="w-full max-w-sm mx-auto mt-8 bg-white p-6 rounded-md shadow-md">
    <h1>{{ this.userName }}</h1>
  </div>

  <reset-password></reset-password>

</template>

<script>
import ResetPassword from '@/components/DDResetPassword.vue';
export default {
  components: {
    ResetPassword,
  },
  data() {
    return {
      userName: this.$store.getters.userName,
    }
  }
}
</script>

<style scoped>

</style>